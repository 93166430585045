import React, {Component} from "react"
import {graphql, StaticQuery, Link} from 'gatsby';
import Layout from "components/Layout/Layout"
import {Container} from "reactstrap"
import styled from 'styled-components'
import {media} from "utils/Media"
import Footer from "components/Layout/Footer"
import Img from "gatsby-image/withIEPolyfill";

const WelcomePage = styled.div`
    position: relative;
    width: 100%;
    
    @media ${media.lg} {
        overflow: auto;
        height: 100vh;
    }
    
    .thank-you {
      margin-top: 3rem;
      text-align: center;
      color: ${props => props.theme.colors.yellow};
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      
      p {
        font-size: 1rem;
      }
      
      .big {
        color: ${props => props.theme.colors.blue};
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
    
    .topImage {
        padding-top: 51px;
    }
    
    h1 {
        color: ${props => props.theme.colors.yellow};
        font-family:"Clarendon W01 Light", Arial, sans-serif;
        font-size: 2.25rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0 auto 1rem;
        text-align: center;
        
        @media ${media.sm} {
            font-size: 3rem;
            margin: 20px auto 0;
        }
    }
    
    
     h2 {
        font-size: 1.375rem;
        color: ${props => props.theme.colors.blue};
        text-transform: uppercase;
        line-height: 120%;
        
        @media ${media.sm} {
            font-size: 2rem;
        }
    }
    
    h3 {
        color: ${props => props.theme.colors.brown};
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 140%;
        
        @media ${media.sm} {
            font-size: 1.125rem;
        }
    }
    
    button {
        border: none;
        background: none;
        color: ${props => props.theme.colors.brown};
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 140%;
        
        @media ${media.sm} {
            font-size: 1.125rem;
        } 
    }
`

class NotFoundPage extends Component {
    render() {
        return (
            <Layout slug="404" title="404 - Page not found" description="404 page for To Kill a Mockingbird playing the Gielgud Theatre, London." page="not-found">
                <WelcomePage>
                    <Img fadeIn={true} fluid={this.props.data.bg.childImageSharp.fluid}
                         className="topImage d-block" alt=""/>

                    <Container fluid={true}>
                        <Container>
                            <div className="thank-you">
                                <h1 className="mb-4">404 - Page not found</h1>
                                <p>Sorry, the page you are looking for is not found! Please go to our <Link to="/">homepage</Link></p>
                            </div>
                            <Footer/>
                        </Container>
                    </Container>
                </WelcomePage>
            </Layout>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
    query {
        bg: file(relativePath: { eq: "top-art.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
    `}
        render={data => (
            <NotFoundPage location={props.location} data={data}/>
        )}
    />
)